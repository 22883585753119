import { Root, rootLoader as loader, useLoaderData } from '@ubo/losse-sjedel';
import type { LinksFunction, RootProps } from '@ubo/losse-sjedel';
import PageNotFound from '~/components/layout/PageNotFound';
import styles from '~/global.css';
import FlexContent from './graphql/FlexContent';
import Queries from './graphql/Queries';
import PostTypes from './graphql/PostTypes';

export const flexData = {
  flexContent: FlexContent,
  queries: Queries,
  postTypes: PostTypes
};

export const links: LinksFunction = () => {
  return [
  {
    rel: 'preload',
    as: 'style',
    href: styles
  },
  {
    href: '/static/nprogress.min.css',
    rel: 'stylesheet',
    async: true
  },
  {
    rel: 'preload',
    as: 'style',
    href: styles
  },
  {
    href: styles,
    rel: 'stylesheet'
  },
  {
    href: '/manifest.json',
    rel: 'manifest'
  },
  {
    rel: 'icon',
    href: '/favicon.png',
    type: 'image/png'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap'
  }];

};

export { loader };

export function CatchBoundary() {
  return <PageNotFound />;
}

export default function App() {
  const data = useLoaderData<RootProps>();

  return (
    // @ts-ignore
    <Root
      {...data}
      headChildren={
      <>
          <script
          dangerouslySetInnerHTML={{
            __html: `
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register("/runtime-service-worker.js").then(
      (registration) => {
        console.log("Service worker registration succeeded:", registration);
      },
      (error) => {
        console.error('Service worker registration failed:', error);
      },
    );
  }
  `
          }} />

        </>} />);



}